import React , { useState, useEffect }from 'react'
import { useParams } from "react-router-dom";
import Meta from "../../Components/Meta";
import * as loginData from "../../Data/ConfirmMail.json";
import ConfirmEmail from "../../Components/ConfirmEmail/confirmEmail";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand
  } from 'mdb-react-ui-kit';
  import Language from "../../Components/Languages"

const ConfirmCore= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(loginData.default[lang]);

  const [meta, setMeta] = useState({
      title: loginData.default[lang].meta.title,
      description: loginData.default[lang].meta.description,
      canonical: loginData.default[lang].meta.canonical,
      image: loginData.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: loginData.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(loginData.default[lang]);
      setMeta({
          title: loginData.default[lang].meta.title,
          description: loginData.default[lang].meta.description,
          canonical: loginData.default[lang].meta.canonical,
          keywords: loginData.default[lang].meta.keywords,
          image: loginData.default[lang].meta.image
      });
  }, [lang]);
  return (
    <>
    <Meta meta={meta} />
    <div >
      <MDBNavbar light bgColor='white' expand='lg' className='me-2'>
          <MDBContainer>
            <MDBNavbarBrand href='#'>
              <img
                src="https://mad-up.com/api/uploads/mad-up/originals/LogomadUpacademy.png"
                height='50'
                alt='logo MAD-UP Academy'
                loading='lazy'
              />
            </MDBNavbarBrand>
            <div className='d-flex w-auto mb-3'> <Language/></div>
          </MDBContainer>
        </MDBNavbar>
    </div>
    <ConfirmEmail h1={data.confirmCore.h1} text1={data.confirmCore.text1} text2={data.confirmCore.text2} text3={data.confirmCore.text3} text4={data.confirmCore.text4}/>
  
    </>
  )
}

export default ConfirmCore;